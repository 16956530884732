import { gsap } from "gsap";

import { navToggle, menuOpenDuration } from "./variables";
import { closeNavMenu } from "./navigation";

// Standard page transition
function standardPageTransition() {
    // move the loader over the screen
    var tl = gsap.timeline();
    tl.to(".loading-screen", {
      duration: menuOpenDuration,
      width: "100%",
      left: "0%",
      ease: "expo.inOut",
    });
  
    // move the loader all the way to the right
    tl.to(".loading-screen", {
      duration: menuOpenDuration,
      width: "100%",
      left: "100%",
      ease: "expo.inOut",
      delay: 0.1,
    });
  
    // reset the position of the loader
    tl.set(".loading-screen", { left: "-100%" });
}

// Page transition function for moving between pages
export function pageTransition() {
    // if the menu is closed
    if (navToggle?.getAttribute("aria-expanded") == "false") {
      standardPageTransition(); // Standard page transition
    } else {
      closeNavMenu(); // Close navigation menu
      navToggle?.setAttribute("aria-expanded", "false"); // Set nav toggle to closed
    }
  }