import Swiper from "swiper";
import { Autoplay, Navigation } from "swiper/modules";
// import Swiper and modules styles
import "swiper/swiper.scss";

function wrap(el, wrapper) {
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

export function registerSlider(slider) {
  slider.classList.add("swiper-wrapper");

  slider.querySelectorAll("figure").forEach((slide) => {
    slide.classList.add("swiper-slide");
    // console.info('Registered image slider: ' + slide)
  });

  wrap(slider, document.createElement("div"));

  // console.log('got wrapper!')
  const swiper: Swiper = new Swiper(slider.parentElement, {
    // configure Swiper to use modules
    modules: [Autoplay],
    autoplay: {
      delay: 5000,
    },
    spaceBetween: 10,
    // direction: 'horizontal',
    slidesPerView: 1.3,
    loop: true,
  });
}

export function registerHireSlider(slider) {
  slider.classList.add("swiper-wrapper");

  slider.querySelectorAll("figure").forEach((slide) => {
    slide.classList.add("swiper-slide");
    // console.info('Registered image slider: ' + slide)
  });

  const div = document.createElement("div");
  const nextButton = document.createElement("div");
  const prevButton = document.createElement("div");

  nextButton.classList.add("swiper-button-next");
  nextButton.classList.add("swiper-button");
  prevButton.classList.add("swiper-button-prev");
  prevButton.classList.add("swiper-button");
  nextButton.setAttribute("role", "button");
  prevButton.setAttribute("role", "button");
  prevButton.innerHTML = `<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M2.7 256l22.6 22.6 192 192L240 493.3 285.3 448l-22.6-22.6L93.3 256 262.6 86.6 285.3 64 240 18.7 217.4 41.4l-192 192L2.7 256z"/></svg>`;
  nextButton.innerHTML =
    '<svg class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M317.3 256l-22.6 22.6-192 192L80 493.3 34.7 448l22.6-22.6L226.7 256 57.4 86.6 34.7 64 80 18.7l22.6 22.6 192 192L317.3 256z"/></svg>';

  wrap(slider, div);

  div.appendChild(prevButton);
  div.appendChild(nextButton);

  const swiper = new Swiper(div, {
    // modules: [Navigation],
    modules: [Navigation],

    spaceBetween: 0,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });
}
