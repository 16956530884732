import { gsap } from "gsap";

import { navToggle, menuOpenDuration } from "./variables";

// Open nav function
export function openNavMenu() {
    navToggle?.setAttribute("aria-expanded", "true");
    var tl = gsap.timeline();
  
    // move the container into view
    tl.to(".full-menu", {
      duration: menuOpenDuration,
      width: "100%",
      left: "0%",
      ease: "expo.inOut",
    });
  
    // fade in the list items
    gsap.set(".full-menu .fade-in", { autoAlpha: 0, x: -50 });
    tl.to(".full-menu .fade-in", {
      duration: 0.3,
      autoAlpha: 1,
      delay: -0.2,
      stagger: 0.08,
      x: 0,
    });
}
  
  // Close nav function
export function closeNavMenu() {
    const tl2 = gsap.timeline();
    const fadeInItems2 = ".full-menu .fade-in";
  
    // fade out the list items
    gsap.set(fadeInItems2, { autoAlpha: 1, x: 0 });
    tl2.to(fadeInItems2, { duration: 0.2, autoAlpha: 0, stagger: 0.05, x: 50 });
  
    // move the container to the right
    tl2.to(".full-menu", {
      duration: menuOpenDuration,
      width: "100%",
      left: "100%",
      ease: "expo.inOut",
      delay: 0,
    });
  
    // reset the menu
    tl2.set(".full-menu", { left: "-100%" });
  
    navToggle?.setAttribute("aria-expanded", "false");
}

// Function that controls the opening and closing of the full menu
export function openFullNav() {
  if (navToggle?.getAttribute("aria-expanded") == "true") {
    closeNavMenu();
  } else if (navToggle?.getAttribute("aria-expanded") == "false") {
    openNavMenu();
  }
}