//  ____________  //
// <  IMPORTS!  > //
//  ------------  //
////////////////////

// parcel
import "../sass/style.scss";

// animation
import { gsap } from "gsap";
import barba from "@barba/core";

// slider
import { tns } from "tiny-slider";

// modules
import { navToggle, fullMenu, menuOpenDuration } from "./modules/variables";
import { registerSlider, registerHireSlider } from "./modules/swiper";
import { openFullNav, closeNavMenu } from "./modules/navigation";
import { pageTransition } from "./modules/animation";
import { reloadFilters } from "./modules/events";

// ____________
// < VARIABLES! >
//  ------------
//         \   ^__^
//          \  (oo)\_______
//             (__)\       )\/\
//                 ||----w |
//                 ||     ||

// var btns = document.querySelectorAll(".filter-container a");

// if (history.scrollRestoration) {
//   history.scrollRestoration = 'manual';
// }

// Prevents hard refresh/menu lock in when navigating to the page you are currently on
function preventHardRefresh() {
  // Add event listener to prevent hard reload
  // on same page navigation
  var links = document.querySelectorAll(".menu-item a[href]");
  var cbk = function (e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault();
      e.stopPropagation();
      var tl = gsap.timeline();

      // if the menu is already open
      if (navToggle?.getAttribute("aria-expanded") == "true") {
        closeNavMenu();
        navToggle?.setAttribute("aria-expanded", "false");
      }
    }
  };

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", cbk);
  }
}

// Delay function (used in Barba.js page transition)
function delay(n) {
  n = n || 2000;
  return new Promise((done) => {
    setTimeout(() => {
      done();
    }, n);
  });
}

// ____________
// < BARBA JS! >
//  ------------
//         \   ^__^
//          \  (oo)\_______
//             (__)\       )\/\
//                 ||----w |
//                 ||     ||

// barba.js set up
barba.init({
  // debug: true,
  // sync: true,
  prevent: ({ href }) => href.includes("uefa"),
  // prevent: ({ el }) => el.parentElement.classList.contains("prevent"),
  transitions: [
    {
      name: "default-transition",
      async leave(data) {
        const done = this.async();
        pageTransition();
        // console.log('page transition function complete');
        await delay(menuOpenDuration * 900);
        // console.log('await complete');
        done();
      },
      async enter(data) {
        // registerAllSliders();
        // contentAnimation();
      },
    },
    {
      name: "single-event",
      to: {
        namespace: ["single-event"],
      },
      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay(menuOpenDuration * 900);
        // console.log('await complete');
        done();
      },
      async enter(data) {
        // registerAllSliders();
        // contentAnimation();
      },
    },
  ],
  views: [
    {
      namespace: "home",
      afterLeave() {
        DiceEventListWidget.destroy();
      },
      afterEnter(data) {
        // registerAllEventSliders();
        setTimeout(() => {
          DiceEventListWidget.create({
            information: "simple",
            eventTitle: "event",
            showImages: true,
            showAudio: false,
            showNewBadge: false,
            hidePostponed: true,
            hideCancelled: true,
            layout: "gallery",
            roundButtons: false,
            theme: "dark",
            fontFamily: "inherit",
            partnerId: "2bdac531",
            apiKey: "FYjGXtYQo72szMQZnUN7Y2g4ZwjPDylH4OzWbIYK",
            version: 2,
            showPrice: true,
            __p: false,
            __pc: {},
            highlightColour: "white",
            numberOfEvents: "4",
            venues: ["HERE at Outernet"],
          });
        }, 20);
        // registerAllEventSliders();
        registerAllSliders();
        registerAllHireSliders();
      },
    },
    {
      namespace: "hire",
      // afterEnter(data) {
      //   // setUpModal();
      //   // registerAllHireSliders();
      //   // addHireFormSubmitListener();
      //   // setUpFormValidation();
      // },
    },
    {
      namespace: "events",
      afterLeave() {
        DiceEventListWidget.destroy();
      },
      afterEnter(data) {
        // reloadFilters();
        setTimeout(() => {
          DiceEventListWidget.create({
            information: "simple",
            eventTitle: "event",
            showImages: true,
            showAudio: false,
            showNewBadge: false,
            hidePostponed: true,
            hideCancelled: true,
            layout: "gallery",
            roundButtons: false,
            theme: "dark",
            fontFamily: "inherit",
            partnerId: "2bdac531",
            apiKey: "FYjGXtYQo72szMQZnUN7Y2g4ZwjPDylH4OzWbIYK",
            version: 2,
            showPrice: true,
            __p: false,
            __pc: {},
            highlightColour: "white",
            venues: ["HERE at Outernet"],
          });
        }, 20);
        // console.log("test");
      },
      // afterEnter(data) {
      //   reloadFilters();
      // },
    },
    {
      namespace: "whats-here",
      afterEnter(data) {
        registerAllSliders();
      },
    },
  ],
});

// then later in the code...

// barba global 'enter' hook
barba.hooks.enter((data) => {
  scrollTo(0, 0);
  // console.log(barba.history.previous.scroll);
  // console.log(scrollX); console.log(scrollY);
});
barba.hooks.afterEnter(() => {
  // document.querySelector('.entry-content').addEventListener('load', afterLoad);
  // reloadFilters();
  // afterLoad();
  setTimeout(() => {
    playHeaderVideo();
  }, 500);
});

barba.hooks.beforeLeave((data) => {});
// barba global 'after' hook
barba.hooks.after(() => {});
// barba global 'leave' hook
barba.hooks.leave((data) => {});

//  _________________
// < EVENT LISTENERS! >
//  -----------------
//         \   ^__^
//          \  (oo)\_______
//             (__)\       )\/\
//                 ||----w |
//                 ||     ||

// Calls essential functions on load
window.addEventListener("DOMContentLoaded", function () {
  // addEventFilterListener();
  preventHardRefresh();
});

// NAV TOGGLERS
// generic 'open' listener
navToggle?.addEventListener("click", openFullNav);
// open menu with space or enter when highlighted
navToggle?.addEventListener("onkeydown", (event: KeyboardEventInit) => {
  if (event.code === "Space" || event.code === "Enter") {
    openFullNav();
  }
});
// close menu with 'Escape' key
document.addEventListener("keydown", (event) => {
  if (navToggle?.getAttribute("aria-expanded") == "true") {
    if (event.key === "Escape") {
      closeNavMenu();
    }
  }
});

// function afterLoad() {
function registerEventSlider(element, index, array) {
  tns({
    container: element,
    // mode: 'gallery',
    slideBy: "page",
    loop: false,
    items: 1,
    controls: true,
    autoHeight: false,
    mouseDrag: true,
    controlsContainer: ".event-slider-controls-container",
    gutter: 8,
    edgePadding: 0,
    nav: false,
    // slideBy: 1,
    rewind: true,
    responsive: {
      600: {
        gutter: 8,
        items: 2,
      },
      900: {
        items: 3,
      },
    },
  });
}
// loops through event sliders and calls 'registerEventSlider' on each
function registerAllEventSliders() {
  const eventSliderWrappers = document.querySelectorAll(".event--cta");
  eventSliderWrappers.forEach(registerEventSlider);
}

// Loops through sliders and calls 'registerSlider' on each
function registerAllSliders() {
  const sliderWrappers = document.querySelectorAll(
    ".wp-block-gallery.columns-3"
  );
  sliderWrappers.forEach(registerSlider);
}

// console.log("hjello");

function registerAllHireSliders() {
  // multiTns = [];
  const sliderWrappers = document.querySelectorAll(".wp-block-gallery.tester");
  sliderWrappers.forEach(registerHireSlider);
}

const instagramLink = document.querySelectorAll(".fa-instagram");

instagramLink.forEach((item: Element | null) => {
  item?.parentElement?.setAttribute("aria-label", "Instagram");
});

const tiktokLink = document.querySelectorAll(".fa-tiktok");
tiktokLink.forEach((item: Element | null) => {
  item?.parentElement?.setAttribute("aria-label", "TikTok");
});

const twitterLink = document.querySelectorAll(".fa-twitter");
twitterLink.forEach((item: Element | null) => {
  item?.parentElement?.setAttribute("aria-label", "Twitter");
});

function playHeaderVideo() {
  const headerVideo: HTMLVideoElement | null = document.querySelector(
    ".wp-block-cover video"
  );
  if (headerVideo) {
    headerVideo.play();
  }
}

document.addEventListener("load", playHeaderVideo);
